import styled from "@emotion/styled";

export const VariantsSelectorContainer = styled.div`
margin-top: 5%;
&>p {
  margin: 10px 0;
}

&>.variants-inner-container {
  &>p {
    font-weight: 500;
    font-size: 12px;
    margin: 10px 0;
  }
  .attrName{
    font-size: 1.5rem;
    margin-bottom: 5px;
  }
}

.variants {
  display: flex;
  gap: 16px;
  max-width: 312px;
  overflow-x: auto;

  @media (max-width: 768px) {
    max-width: 100%;
  }

}
`

export const Variant = styled.div`
cursor: pointer;
border: 1px solid grey;
border-radius: 3px;
max-width: 105px;
min-width: 100px;
// min-height: 70px;
padding: 8px;
display: flex;
flex-direction: column;
align-items: center;

p {
  // margin-top: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

&.active {
  border: 2px solid ${({ theme }: any) => theme.productAddToCartBg};
}

`