import styled from '@emotion/styled';

export const AddToCartModalStyled = styled.div<{}>`
padding: 15px;
height: 100%;
padding-top:20px;

.prodImage {
  height: 176px;
  width: 100%;
  object-fit: contain;
  display: block;
  margin: 0 auto;
}

h1 {
  text-align: center;
  font-size: 2rem;
  font-weight: bolder;
  margin-bottom: 10%;
}
h2 {
  text-align: center;
  font-size: 1.4rem;
  font-weight: bolder;
  margin-bottom: 10px;
}
.price {
  text-align: center;
  font-size: 1.4rem;
  font-weight: bolder;
}

.addToCart{
  display:inline-flex;
  margin-top:15px;
  width:100%;
  justify-content: center;
}
.closeModal {
  cursor: pointer;
  position: absolute;
  z-index: 10;
  font-size: 2.3rem;
  top: 7px;
  right:10px;
}
`;
